import React, { useEffect, useState } from "react";
import "../../index.css"
import styles from "./style.module.css";
import Header from "../partials/AdminHeader";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DecVideCall from "../../images/dec-video-call.png";
import MuteSwitch from "../../images/mute-switch.png";
import UnmuteSwitch from "../../images/mike.png";
import UnmuteVideo from "../../images/muteVideo.png";
import SwtchVideo from "../../images/vid-switch.png";
import yeslocker from "../../images/new images/longyes.png";
import nolocker from "../../images/new images/longno.png";
import shortyes from "../../images/new images/shortyes.png";
import shortno from "../../images/new images/shortno.png";
import lock from "../../images/Lock.svg";
import Video from "../../images/video.png";
import Swal from "sweetalert2";
import mockup from "../../images/new images/gray.png";
import "sweetalert2/dist/sweetalert2.min.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import reportWebVitals from "./../../reportWebVitals";
import ReactPlayer from "react-player";
const url = process.env.REACT_APP_APP_BACK_URL;
const AdminVideoChatting = () => {
    let navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.user);
    let { slug } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [locker_display, setlocker_display] = useState(true);
    const [isDisplay, setIsDisplay] = useState(false);
    const [isLockerOpen, setIsLockerOpen] = useState(false);
    const [videoShow, setVideoShow] = useState(true);
    const [getLocker, setLocker] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLocker, setselectedLocker] = useState("");
    const [stream, setStream] = useState();
    const [isAudioMuted, setIsAudioMuted] = useState(true);
    const [audioTrack, setAudioTrack] = useState(null);
    const [videoTrack, setVideoTrack] = useState(null);
    const [isVideoMuted, setIsVideoMuted] = useState(true);

    async function setStreamFun() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true,
            });
            setStream(stream);
            const audioTrack = stream.getAudioTracks()[0];
            // Mute the audio track by default
            audioTrack.enabled = true;
            const videoTrack = stream.getVideoTracks()[0];
            // Mute the video track by default
            videoTrack.enabled = true;
            setStream(stream);
            setAudioTrack(audioTrack);
            setVideoTrack(videoTrack);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        siteLockers();
        setStreamFun();
        return () => {
            stopCamera();
        };
    }, []);
    function stopCamera() {
        if (stream) {
            // Stop all tracks in the stream
            stream.getTracks().forEach((track) => track.stop());

            // Optional: If you want to clear the stream object
            // Note: This is not always necessary, depending on your use case
            setStream(null);
        }
    }
    // get all site
    const siteLockers = async () => {
        try {
            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Access-Control-Allow-Origin", "*");
            headers.append("Access-Control-Allow-Credentials", "true");
            const response = await fetch(`${url}/api/sites/lockers/${slug}`, {
                mode: "cors",
                method: "GET",
                headers: headers,
            });
            const json = await response.json();

            if (json.success) {
                console.log(json, "Lockers Response");
                setselectedLocker("");
                setIsDisplay(true);
                setLocker(json.lockers);
                console.log(json.lockers[0]._id);
            } else {
                toast.error(json.error);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const toggleAudioMute = () => {
        if (audioTrack) {
            audioTrack.enabled = !isAudioMuted;
            setIsAudioMuted(!isAudioMuted);
        }
    };
    const toggleVideoMute = () => {
        if (videoTrack) {
            videoTrack.enabled = !isVideoMuted;
            setIsVideoMuted(!isVideoMuted);
        }
    };
    // open locker
    const openLocker = async (id) => {
        let state = "2";
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        setIsLockerOpen(true);
        setIsLoading(true);
        const response = await fetch(`${url}/api/sites/relay/state/update`, {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                id: id,
                state: state,
            }),
        });

        setIsLockerOpen(false);
        const json = await response.json();
        if (json.success) {
            toast.success(json.message);
        } else {
            toast.error(json.error);
        }
        setIsLoading(false);
        siteLockers();
    };
    //   modal functions
    const openModal = (id) => {
        setIsModalOpen(true);
        setselectedLocker(id);
    };
    // set id
    const displayOpen = async (id) => {
        const result = await Swal.fire({
            title: "Do you want to open this locker?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes!",
            cancelButtonText: "No",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setselectedLocker(id);
            // if (selectedLocker === id) {
            openLocker(id);
            // }
        }
    };

    const displayOpen1 = async (id) => {
        const result = await Swal.fire({
            title: "Do you want to open this locker?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes!",
            cancelButtonText: "No",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setselectedLocker(id);
            // if (selectedLocker === id) {
            openModal(id);
            // }
        }
    };

    const closeModal = () => {
        setselectedLocker("");
        setIsModalOpen(false);
    };
    // return to available locker
    const returnLocker = async () => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        setIsLoading(true);
        const response = await fetch(
            `${url}/api/sites/changeToAvailable/${selectedLocker}`,
            {
                mode: "cors",
                method: "GET",
                headers: headers,
            }
        );

        const json = await response.json();
        if (json.success) {
            toast.success(json.message);
            siteLockers();
            setIsLoading(false);
            setIsModalOpen(false);
        } else {
            toast.error(json.error);
        }
    };
    return (
        <div className={styles.heading}>
            <div className="body-wrap">
                <div className="container-fluid px-0">
                    <Header />
                    <div className="container-fluid text-center p-3 ">
                        <div className="">
                            <div className="form-box card-box">
                                <div className="row">
                                    <div className="col-lg-12 d-flex  justify-content-center align-items-center call-side">
                                        <div
                                            className="d-flex gap-2 justify-content-center align-items-center flex-row"
                                            onClick={() => {
                                                setVideoShow(true);
                                                setStreamFun();
                                            }}
                                        >

                                        </div>
                                        <div className="h-100 ">
                                            <div
                                                className=" w-sm-25 col-sm-12 mt-5 rounded-1 d-flex justify-content-center align-items-center flex-column "
                                                style={{
                                                    background: "linear-gradient(#C0C0C0, gray)",
                                                    position: "relative",
                                                    overflow: "auto",
                                                }}
                                            >

                                                {stream && videoShow && (
                                                    <>
                                                        <ReactPlayer
                                                            playing
                                                            muted
                                                            style={{ position: "absolute", top: "3%", left: "85%" }}
                                                            height="12%"
                                                            width="12%"
                                                            url={stream}
                                                        />
                                                        <ReactPlayer
                                                            playing
                                                            muted
                                                            height="100%"
                                                            width="100%"
                                                            url={stream}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            {videoShow && (
                                                <>
                                                    {/* <button
                                                        onClick={() => {
                                                            setVideoShow(false);
                                                            navigate(`/${slug}/dashboard1`);
                                                            stopCamera();
                                                        }}
                                                        className="rounded mt-3 border-0 p-1 px-4"
                                                    >
                                                        End Call
                                                    </button> */}
                                                    <div className="icons-wrap-end-call mt-3">
                                                        {/* <div className="icon-call">
                                                              <img src={SwtchVideo} alt="" />
                                                            </div> */}
                                                        <div
                                                            className="icon-call d-flex justify-content-between align-items-center"
                                                            style={{ background: "rgba(64, 30, 30, 0.15)", width: "40px", height: "40px" }}

                                                            onClick={toggleVideoMute}
                                                        >
                                                            {isVideoMuted ? (
                                                                <img
                                                                    src={SwtchVideo}
                                                                    alt="Unmute Video"
                                                                    className="mx-2"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={UnmuteVideo}
                                                                    alt="Mute Video"
                                                                    className="mx-2"
                                                                />
                                                            )}
                                                        </div>

                                                        <div className="icon-call dec-call-video">
                                                            <img
                                                                src={DecVideCall}
                                                                alt=""
                                                                onClick={() => {
                                                                    setVideoShow(false);
                                                                    navigate(`/${slug}/timer`);
                                                                    stopCamera();
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="icon-call  d-flex justify-content-between align-items-center"
                                                            style={{ background: "rgba(64, 30, 30, 0.15)", width: "40px", height: "40px" }}
                                                            onClick={toggleAudioMute}
                                                        >
                                                            {isAudioMuted ? (
                                                                <img src={UnmuteSwitch} alt="" className="mx-2" />
                                                            ) : (
                                                                <img src={MuteSwitch} alt="" className="mx-2" />
                                                            )}
                                                        </div>

                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        contentLabel="Example Modal"
                        className="modal-content"
                        style={{
                            overlay: {
                                backgroundColor: "rgb(43 41 41 / 75%)", // Update the background color here
                            },
                            content: {
                                backgroundColor: "white", // Update the modal content background color here
                                // You can add other styling properties as needed
                            },
                        }}
                        shouldCloseOnOverlayClick={false}
                    >
                        <img src={lock} alt="lock" className="modal-image" />
                        <p className="modal-text mt-3">
                            Do you want to release this locker?
                        </p>
                        <div className="modal-button">
                            <button className="modal-btn1" onClick={returnLocker}>
                                Yes
                            </button>
                            <button className="modal-btn2" onClick={closeModal}>
                                No
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default AdminVideoChatting;
