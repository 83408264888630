import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import logoutImage from "../../images/logout16.png";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import { toast } from "react-toastify";
import {
  checkFileExists,
  formattedDateTime1,
  formattedDateTime,
} from "../../utils/generic";
import "../../simplelocker.css";

const url = process.env.REACT_APP_APP_BACK_URL;
const imageUrl = process.env.REACT_APP_APP_IMAGE_URL;
const baseUrl = process.env.REACT_APP_BASE_URL;

let idleTimer;
const Header = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const isAuthenticated = useSelector((state) => state.user);
  let { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [getSite, setSite] = useState({});
  const [siteLogo, setSiteLogo] = useState(imageUrl + "/locker_logo.png");
  const [siteName, setSiteName] = useState();
  useEffect(() => {
    siteData();
  }, []);
  const idleTimeoutInSeconds = isAuthenticated ? isAuthenticated.idle_time : 0; // 5 minutes (adjust as needed)
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  // Function to reset the idle timer
  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(handle_logout, idleTimeoutInSeconds * 1000);
  };
  // Function to simulate a logout action
  const handle_logout = async (e) => {
    const logouts = localStorage.removeItem("token");
    navigate(`/`);
    dispatch(logout());
  };
  const handle_logout_function = async (e) => {
    clearTimeout(idleTimer); // Clear the idle timer
    const logouts = localStorage.removeItem("token");
    localStorage.removeItem("siteuser");
    navigate(`/${slug}/pin`);
    dispatch(logout());
  };
  useEffect(() => {
    if (isAuthenticated && isAuthenticated.role !== "super admin") {
      resetIdleTimer();
      const activityEvents = ["mousemove", "keydown", "mousedown", "scroll"];
      const onActivity = () => {
        resetIdleTimer();
      };
      activityEvents.forEach((event) => {
        window.addEventListener(event, onActivity);
      });
      return () => {
        activityEvents.forEach((event) => {
          window.removeEventListener(event, onActivity);
        });
      };
    }
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // get all site
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSite(json.site);

        const isExist = await checkFileExists(json?.site?.image);
        setSiteLogo(json.site.image);
       
        setSiteName(json.site.name)
      } else {
        toast.error("No site found! Enter valid site");
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log(siteName);
  return (
    <div>
      <header className="header">
        <div className="row mb-2">
          <div className="prof-sett">
            <div className="col-lg-8 text-center">
              <div className="main-heading">
                <div className="locker-logo">
                  <div className="locker-style-image">
                    <img
                      className="logo-image-style img-fluid mx-auto mt-2"
                      src={siteLogo}
                      alt="Logo"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="locker-timer">
                <p>{formattedDateTime(currentDateTime)}</p>
                <p>{formattedDateTime1(currentDateTime)}</p>
                {isAuthenticated && isAuthenticated.role !== "super admin" && (
                  <button
                    onClick={handle_logout_function}
                    className="btn btn-prim d-flex justify-content-center"
                    id="logout-btn"
                  >
                    <img src={logoutImage} alt="logout" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
