import React, { useEffect, useState } from "react";
import styles from './style.module.css';
import SearchIcon from '../../assets/icons/search.svg'
import Sidebar from "../../Components/partials/Sidebar";
import Header from "../../Components/partials/Header2";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
// import QrIcon from "../../../images/dash-qr.png";
import Images from "../modal/Images";
const Site = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isImageLarge, setIsImageLarge] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ status: "all", name: "" });
  const handleInput = (e) => {
    setCurrentPage(1);
    setCredentials((prevCredentials) => {
      const updatedCredentials = { ...prevCredentials, [e.target.name]: e.target.value };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  useEffect(() => {
    fetchData(credentials);
  }, [currentPage]);
  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/sites/list?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}&status=${fetchCredentials.status}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      if (json.sites.length > 0) {
        setIsDisplay(true);
        setData(json.sites);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handleResetBtn = () => {
    const updatedCredentials = {
      name: '',
      status: 'all',
    };
    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);
    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Credentials', 'true');
        const response = await fetch(`${url}/api/sites/delete/${id}`, {
          mode: 'cors',
          method: 'DELETE',
          headers: headers,
        });
        if (response.ok) {
          fetchData(credentials); // Refresh the records list after successful deletion
          Swal.fire(
            'Deleted!',
            'Site deleted successfully!',
            'success'
          );
        } else {
          Swal.fire(
            'Error',
            'An error occurred while deleting the record.',
            'error'
          );
        }
      }
    } catch (error) {
      console.log('Error deleting record:', error);
      Swal.fire(
        'Error',
        'An error occurred while deleting the record.',
        'error'
      );
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  const toggleImageSize = () => {
    setIsImageLarge(!isImageLarge);
  };
  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl('');
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>sites</h3>
                            <p>
                              List of all the sites registered in the system
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end">

                          <div className="filter-right-sec">
                            <div className="filter-wrap">
                              <div className="filter-box" onClick={toggleFilterSection}>
                                <i className="fas fa-filter" />
                              </div>
                            </div>
                            <Link
                              to="/site/add"
                              className="btn btn-prim w-auto"
                            >
                              Add New site
                            </Link>
                          </div>
                        </div>
                      </div>
                      {isFilterVisible && (
                        <>
                          <motion.div
                            className={`row mt-5 filter-section`}
                            initial={{ opacity: 0 }} // Initial state (hidden)
                            animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                            transition={{ duration: 1.2, delay: 0.1 }}
                          >
                            <div className="col-12">
                              <div className="filter-right-sec filter-wrap-chat">
                                <div className="search-wrap">
                                  <input type="search" placeholder="search by username" name="name" value={credentials.name} onChange={handleInput} />
                                  <div className="search-box">
                                    <img src={SearchIcon} alt="" />
                                  </div>
                                </div>
                                <div className="filter-select d-flex align-items-center">
                                  <label htmlFor="">Status</label>
                                  <select name="status" id="" value={credentials.status} onChange={handleInput}>
                                    <option value="all">Choose Status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                  </select>
                                </div>
                                <Link
                                  to="" onClick={handleResetBtn}
                                  className="btn btn-prim w-auto"
                                >
                                  RESET
                                </Link>
                              </div>
                            </div>
                          </motion.div>
                        </>
                      )}
                    </div>
                    {/* row */}
                    <div className="table-wrap">
                      <div className="row">
                        <div className="col-12">
                          <div className={styles.card_box}>
                            <div className={styles.tables_wrap}>
                              <div className="table-responsive">
                                <table className="table align-middle">
                                  <thead>
                                    <tr>
                                      <th>site name</th>
                                      <th>status</th>
                                      <th>complete address</th>
                                      <th>site ip</th>
                                      <th>Port</th>

                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody >
                                    {isLoading && (
                                      <>
                                        <tr>
                                          <td colSpan={5} className="text-center" style={{ background: "transparent" }}>
                                            <HashLoader
                                              color={`#0b0d41`}
                                              loading={isLoading}
                                              size={40}
                                              aria-label="Loading Spinner"
                                              data-testid="loader"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    {isDisplay &&
                                      data.map((item, i) => (
                                        <motion.tr
                                          key={item._id}
                                          initial={{ opacity: 0 }}
                                          animate={{ opacity: 1 }}
                                          transition={{ duration: 1.2, delay: i * 0.1 }}
                                        >
                                          <td>{item.name}</td>
                                          <td>
                                            <span className="status success">
                                              {item.status}
                                            </span>
                                          </td>
                                          <td>
                                            {item.address}
                                          </td>
                                          <td className="text-lowercase">{item.ip_address}</td>
                                          <td>
                                            {item.port}
                                          </td>
                                          <td className="d-flex ">
                                            <Link to={`/edit/site/${item._id}`}>
                                              <button
                                                type="button"
                                                className="btn btn-edit tb-btn"
                                              >
                                                Edit
                                              </button>
                                            </Link>
                                            <Link to={`/${item.slug}/lockers`}>
                                              <button
                                                type="button"
                                                className="btn btn-success tb-btn"
                                              >
                                                Lockers
                                              </button>
                                            </Link>
                                            <button
                                              type="button"
                                              className="btn btn-delete tb-btn"
                                              onClick={() => handleDelete(item._id)}
                                            >
                                              Remove
                                            </button>
                                          </td>
                                        </motion.tr>
                                      ))
                                    }
                                    {isNotFound && (
                                      <tr>
                                        <td colSpan={6} className="text-center">
                                          No Data Found
                                        </td>
                                      </tr>
                                    )
                                    }
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-num">
                                {data.length > 0 && (
                                  <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    pageCount={totalPages}
                                    forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                                    onPageChange={handlePageChange}
                                    containerClassName={' dig-num'}
                                    pageClassName={'dig-num'}
                                    previousClassName={'num-btns'}
                                    nextClassName={'num-btns'}
                                    disabledClassName={'pagination-disabled'}
                                    activeClassName={'pagination-active'}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Site;
