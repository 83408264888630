import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../partials/UserHeader";
// Utils
const PrivateRoutePin = ({ children }) => {
  const authed = localStorage.getItem("token");
  const storedToken = localStorage.getItem("token-expiration");
  const currentTime = Math.floor(Date.now() / 1000);
  
  if (authed && storedToken > currentTime) {
    return (
      <>
       
        <Outlet />
      </>
    );
  } else {
    return <Navigate to="/" replace />;
  }
};
//return authed ? children : <Navigate to="/" replace /> };
export default PrivateRoutePin;
