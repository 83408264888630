import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/partials/Header";
import Code from "./Components/Code";
import Signup from "./Components/auth/Signup";
import AdminRoutes from "./Components/auth/AdminRoutes";
import PrivateRoute from "./Components/auth/PrivateRoute";
import PrivateRoutePin from "./Components/auth/PrivateRoutePin";
import SiteList from "./Components/site/Site";
import Add from "./Components/site/Add";
import Edit from "./Components/site/Edit";
import Lockers from "./Components/Lockers/List";
import LockersEdit from "./Components/Lockers/Edit";
import Profile from "./Components/profile/Profile";
import EditProfile from "./Components/profile/EditProfile";
import VaHeader from "./Components/partials/VAHeader";
import AdminPage from "./Components/pin_code/AdminPage";
import LoginPage from "./Components/pin_code/LoginPage";
import SiteUser from "./Components/pin_code/SiteUser";
import ResetPassword from "./Components/pin_code/ResetPassword";
import ForgotPassword from "./Components/pin_code/ForgotPassword";
import AdminLoginPage from "./Components/pin_code/AdminLoginPage";
import Logo from "./Components/site/Logo";

import DashboardScr from "./Components/dashboard/DashboardScr";
import VideoChat from "./Components/dashboard/VideoChat";
import Dashboardcopy from "./Components/dashboard/Dashboardcopy";
import InvidDashboard from "./Components/dashboard/InvidDashboard";
import VADashboard from "./Components/dashboard/VADashboard";
import TimeScr from "./Components/dashboard/TimeScr";
import InActivity from "./Components/dashboard/InActivity";
import NewDashboard from "./Components/dashboard/NewDashboard";
import LiveStreaming2 from "./Components/dashboard/LiveStreaming2";
import VideoChatting from "./Components/dashboard/VideoChatting";
import AdminVideoChatting from "./Components/dashboard/AdminVdoChat";

function App() {
  useEffect(() => {
    // const handleTouchMove = (e) => {
    //   if (e.touches.length === 2) {
    //     e.preventDefault();
    //   }
    // };
    // document.addEventListener("touchmove", handleTouchMove, { passive: false });
    // return () => {
    //   document.removeEventListener("touchmove", handleTouchMove);
    // };
  }, []);
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<SiteUser />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/code/:slug" element={<Code />} />
          <Route exact path="/:slug/pin" element={<LoginPage />} />
          <Route
            exact
            path="/:slug/admin/pin"
            element={
              <>
                <Header />
                <AdminLoginPage />
              </>
            }
          />
          <Route exact path="/dashboard" element={<Dashboardcopy />} />
          <Route exact path="/:slug/newdashboard" element={<NewDashboard />} />
          <Route exact path="/video" element={<VideoChat />} />
          <Route exact path="/:slug/videochat" element={<VideoChatting />} />
          <Route
            exact
            path="/:slug/VA"
            element={
              <>
                <VaHeader />
                <VADashboard />
              </>
            }
          />
          <Route
            exact
            path="/live/streaming/:id"
            element={<LiveStreaming2 />}
          />
          <Route
            exact
            path="/:slug/adminvideochat"
            element={<AdminVideoChatting />}
          />
          <Route exact path="/admin" element={<AdminPage />} />
          <Route exact path="/forgot/password" element={<ForgotPassword />} />
          <Route
            exact
            path="/reset/password/:token"
            element={<ResetPassword />}
          />

          {/* Invid Routes */}
          <Route element={<PrivateRoutePin />}>
            <Route
              exact
              path="/:slug/dashboard1"
              element={<InvidDashboard />}
            />
          </Route>

          {/* User Routes */}


          <Route element={<PrivateRoute />}>
            <Route exact path="/:slug/dashboard" element={<DashboardScr />} />
            <Route exact path="/:slug/timer" element={<TimeScr />} />
            <Route exact path="inActivity" element={<InActivity />} />
          </Route>
          <Route element={<AdminRoutes />}>
            <Route exact path="/:slug/lockers/" element={<Lockers />} />
            <Route exact path="/sites" element={<SiteList />} />
            <Route exact path="/site/add" element={<Add />} />
            <Route exact path="/site/logo/add" element={<Logo />} />
            <Route exact path="/edit/site/:id" element={<Edit />} />
            <Route exact path="/edit/locker/:id" element={<LockersEdit />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/profile/edit" element={<EditProfile />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
export default App;
