import React, { useEffect, useRef, useState } from "react";
import "../../index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./style.module.css";
import Header from "../partials/UserHeader";
import DecVideCall from "../../images/dec-video-call.png";
import MuteSwitch from "../../images/mute-switch.png";
import UnmuteSwitch from "../../images/mike.png";
import UnmuteVideo from "../../images/muteVideo.png";
import SwtchVideo from "../../images/vid-switch.png";
import lock from "../../images/Lock.svg";
import "sweetalert2/dist/sweetalert2.min.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import reportWebVitals from "./../../reportWebVitals";
import ReactPlayer from "react-player";
import Peer from "simple-peer";
import { io } from "socket.io-client";
import backgroundMusicClick from "../../assets/click.mp3";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const url = process.env.REACT_APP_APP_BACK_URL;
const socketUrl = process.env.REACT_APP_APP_SOCKET_URL;
const socket = io.connect(socketUrl);

const VideoChatting = () => {
  let navigate = useNavigate();
  let { slug } = useParams();
  const myButtonRef = useRef(null);
  let peer = null;
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const myVideo = useRef(null);
  const userVideo = useRef(null);
  const connectionRef = useRef(null);
  const leaveCallButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [vaStream, setVaStream] = useState();
  const [videoShow, setVideoShow] = useState(true);
  const clickButton = useRef(null);
  const [getLocker, setLocker] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLocker, setselectedLocker] = useState("");
  const [stream, setStream] = useState();
  const [audioTrack, setAudioTrack] = useState(null);
  const [videoTrack, setVideoTrack] = useState(null);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [callDeclined, setcallDeclined] = useState(false);
  useEffect(() => {
    const setStreamFun = async () => {
      try {
        const streamObj = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        if (myVideo.current) {
          myVideo.current.srcObject = streamObj;
        }
        const audioTrack = streamObj.getAudioTracks()[0];
        audioTrack.enabled = true;
        const videoTrack = streamObj.getVideoTracks()[0];
        videoTrack.enabled = true;
        setStream(streamObj);
        setAudioTrack(audioTrack);
        setVideoTrack(videoTrack);

        socket.on("connectCall", (data) => {
          setIsLoading(true);
          const delayInMilliseconds = 5000; // 10 seconds
          // Update elapsed time every second
          let intervalId = "";
          const timeoutId = setTimeout(() => {
            if (myButtonRef.current) {
              myButtonRef.current.click();
            }
            setIsLoading(false);
          }, delayInMilliseconds);
          // Make sure to clear the timeout if the component unmounts
          return () => {
            clearTimeout(timeoutId);
          };
        });
        socket.on("destroyConnection", (data) => {
          destroyConnection();
          // stopCamera();
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setStreamFun();
    socket.emit("user_login", slug);
    // siteLockers();

    // callUser(slug);
    return () => {
      stopCamera();
    };
  }, [slug]);

  const destroyConnection = async () => {
    setCallEnded(true);
    setcallDeclined(true);
    const result = await Swal.fire({
      title: "Call Declined?",
      text: "Call declined by admin!",
      icon: "warning",
      showCancelButton: true,

      reverseButtons: true,
    });
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;
    // console.log(connectionRef);
    // console.log(userVideo);
    const delayInMilliseconds = 5000; // 10 seconds
    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      if (leaveCallButtonRef.current) {
        leaveCallButtonRef.current.click();
      }
    }, delayInMilliseconds);
    // Make sure to clear the timeout if the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
    // navigate('/chats');
  };
  const clickButtonFun = async () => {
    backgroundAudioClick.play();
  };

  const callUser = async (slug) => {
    try {
      setCallEnded(false);

      // Create a promise that resolves when the stream is set
      const streamPromise = new Promise((resolve, reject) => {
        if (peer) {
          peer.destroy();
        }
        peer = new Peer({
          initiator: true,
          trickle: false,
          stream: stream,
        });
        console.log("peering", peer);
        peer.on("signal", (data) => {
          socket.emit("callUser", {
            caller: "VA" + slug,
            name: slug,
            remote: "",
            signalData: data,
          });
        });
        peer.on("stream", (stream) => {
          setVaStream(stream);
          console.log("streaming", stream);

          if (userVideo.current) {
            userVideo.current.srcObject = stream;
            console.log("strean connected", userVideo.current.srcObject);
          }
          resolve(); // Resolve the promise when the stream is set
        });
        socket.on("callAccepted", (signal) => {
          console.log("signal", signal);
          setCallAccepted(true);
          peer.signal(signal);
        });
        connectionRef.current = peer;
        console.log("connection", connectionRef.current);
      });
      // Wait for the stream to be set before continuing
      await streamPromise;
      // Continue with other functionality that depends on the stream
      // ...
    } catch (error) {
      // Handle any errors that may occur during the process
      console.error("Error in callUser:", error);
    }
  };
  const handleBeforeUnload = (e) => {
    // Clean up resources before the page is unloaded
    // For example, close connections, save data, etc.
    alert("test");
    e.preventDefault();
    return (e.returnValue = "");
  };
  const leaveCall = () => {
    socket.emit("destroyCallConnection", {
      caller: "VA" + slug,
    });
    if (clickButton.current) {
      clickButton.current.click();
    }
    setCallEnded(true);
    setStream(null);
    stopCamera();
    // setcallDeclined(true);
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;
    console.log(connectionRef);
    console.log(userVideo);
    const delayInMilliseconds = 5000; // 10 seconds
    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      window.location.href = `/${slug}/dashboard1`;
    }, delayInMilliseconds);
  };

  // admin leave call
  // leave video call
  const userlLeaveCall = () => {
    stopCamera();
    setCallEnded(false);
    setVideoShow(false);
    setcallDeclined(false);

    setStream(null);
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;
    window.location.href = `/${slug}/dashboard1`;
  };
  function stopCamera() {
    if (stream) {
      // Stop all tracks in the stream
      stream.getTracks().forEach((track) => track.stop());
      // Optional: If you want to clear the stream object
      setStream(null);
    }
  }
  // get all site
  const siteLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();

      if (json.success) {
        console.log(json, "Lockers Response");
        setselectedLocker("");
        setIsDisplay(true);
        setLocker(json.lockers);
        console.log(json.lockers[0]._id);
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const toggleAudioMute = () => {
    if (clickButton.current) {
      clickButton.current.click();
    }
    if (audioTrack) {
      audioTrack.enabled = !isAudioMuted;
      setIsAudioMuted(!isAudioMuted);
    }
  };
  const toggleVideoMute = () => {
    if (clickButton.current) {
      clickButton.current.click();
    }
    if (videoTrack) {
      videoTrack.enabled = !isVideoMuted;
      setIsVideoMuted(!isVideoMuted);
    }
  };
  //   modal functions
  const openModal = (id) => {
    setIsModalOpen(true);
    setselectedLocker(id);
  };
  const closeModal = () => {
    setselectedLocker("");
    setIsModalOpen(false);
  };
  // return to available locker
  const returnLocker = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/sites/changeToAvailable/${selectedLocker}`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );

    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
      siteLockers();
      setIsLoading(false);
      setIsModalOpen(false);
    } else {
      toast.error(json.error);
    }
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          <Header />
          <div className="container-fluid text-center p-3 ">
            <div className="">
              <div className="form-box card-box">
                <div className="row">
                  <div className="col-lg-12 d-flex  justify-content-center align-items-center  call-side">
                    <div
                      className="d-flex gap-2 justify-content-center align-items-center flex-row"
                      onClick={() => {
                        setVideoShow(true);
                      }}
                    ></div>
                    <div className="h-100 ">
                      {stream && videoShow && (
                        <>
                          <div
                            className=" w-sm-25 col-sm-12 mt-5 rounded-1 d-flex justify-content-center align-items-center flex-column "
                            style={{
                              background: "linear-gradient(#C0C0C0, gray)",
                              position: "relative",
                              overflow: "auto",
                            }}
                          >
                            {!callAccepted && (
                              <div
                                className="lds-spinner"
                                style={{
                                  display: "flex",
                                  top: "60px",
                                  left: "-10px",
                                }}
                              >
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            )}

                            <ReactPlayer
                              playing
                              muted
                              style={{
                                position: "absolute",
                                zIndex: "99999999",
                                top: "1%",
                                right: "3%",
                                objectFit: "cover",
                              }}
                              height="25%"
                              width="20%"
                              url={stream}
                            />
                            {/* <video
                              style={{
                                position: "absolute",
                                top: "3%",
                                right: "5%",
                                border: "1px solid black",
                                borderRadius: "5%",
                                width: "25%",
                              }}
                              muted
                              ref={myVideo}
                              autoPlay
                            /> */}

                            {!callEnded ? (
                              <video
                                height="100%"
                                width="100%"
                                ref={userVideo}
                                autoPlay
                                playsInline
                              />
                            ) : (
                              <video autoPlay playsInline />
                            )}
                            {/* {!callAccepted && <h5>Call Connecting ...</h5>} */}
                            {callDeclined && <h5>Call Declined by Admin...</h5>}
                            {/* <ReactPlayer
                              playing
                              muted
                              height="100%"
                              width="100%"
                              url={stream}
                            /> */}
                          </div>
                        </>
                      )}
                      {videoShow && (
                        <>
                          <div className="icons-wrap-end-call mt-3">
                            <div
                              className="icon-call caller-icon d-flex justify-content-center align-items-center"
                              style={{
                                background: "rgb(2, 1, 93)",
                                width: "30px",
                                height: "30px",
                              }}
                              onClick={toggleVideoMute}
                            >
                              {isVideoMuted ? (
                                <img
                                  src={SwtchVideo}
                                  alt="Unmute Video"
                                  className="mx-2 d-flex justify-content-center"
                                />
                              ) : (
                                <img
                                  src={UnmuteVideo}
                                  alt="Mute Video"
                                  className="mx-2 d-flex justify-content-center bg-dark p-1 rounded"
                                />
                              )}
                            </div>
                            <Link onClick={leaveCall}>
                              <div className="icon-call dec-call-video">
                                <img src={DecVideCall} alt="Decline Call" />
                              </div>
                            </Link>
                            <div
                              className="icon-call d-flex  align-items-center"
                              style={{
                                background: "rgb(2, 1, 93)",
                                width: "30px",
                                height: "30px",
                              }}
                              onClick={toggleAudioMute}
                            >
                              {isAudioMuted ? (
                                <img
                                  src={UnmuteSwitch}
                                  alt="UnMute Call"
                                  className="mx-2 d-flex justify-content-center"
                                />
                              ) : (
                                <img
                                  src={MuteSwitch}
                                  alt="Mute Call"
                                  className="mx-2 d-flex justify-content-center bg-dark px-2 py-2 rounded "
                                />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a ref={myButtonRef} onClick={() => callUser(slug)}></a>
          <a ref={leaveCallButtonRef} onClick={userlLeaveCall}></a>
          <a ref={clickButton} onClick={clickButtonFun}></a>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            className="modal-content"
            style={{
              overlay: {
                backgroundColor: "rgb(43 41 41 / 75%)", // Update the background color here
              },
              content: {
                backgroundColor: "white", // Update the modal content background color here
                // You can add other styling properties as needed
              },
            }}
            shouldCloseOnOverlayClick={false}
          >
            <img src={lock} alt="lock" className="modal-image" />
            <p className="modal-text mt-3">
              Do you want to release this locker?
            </p>
            <div className="modal-button">
              <button className="modal-btn1" onClick={returnLocker}>
                Yes
              </button>
              <button className="modal-btn2" onClick={closeModal}>
                No
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default VideoChatting;
