import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import Header from "../partials/Header";
// Utils
const PrivateRoute = ({ children }) => {
  const authed = localStorage.getItem("token");
  const storedToken = localStorage.getItem("token-expiration");
  const currentTime = Math.floor(Date.now() / 1000);
  if (authed && storedToken > currentTime) {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  } else {
    return <Navigate to="/" replace />;
  }
};
//return authed ? children : <Navigate to="/" replace /> };
export default PrivateRoute;
