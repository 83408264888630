import React from "react";
import Logo from "../../images/logo.svg";
import IconDashboard from "../../assets/icons/icon-dashboard.svg";
import { Link, useNavigate } from "react-router-dom";
import IconUser from "../../assets/icons/icon-user.svg";
import IconMedia from "../../assets/icons/icon-media.png";
import { NavLink } from "react-router-dom";
import "../style.module.css";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
const Sidebar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleUserLogout = async (e) => {
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };
  return (
    <div>
      <section className="sidebar_wrap">
        <div className="logo-wrap text-center">
          <img src={Logo} alt="logo" className="logo-sidebar-wrap" />
        </div>
        <ul>
          <li>
            <Link to="/sites">
              <img src={IconDashboard} alt="icon" />
              <span>Sites</span>
            </Link>
          </li>
          <li>
            <Link to="/site/logo/add">
              <img src={IconMedia} alt="icon" />
              <span>Site Logo</span>
            </Link>
          </li>
          <li>
            <Link onClick={handleUserLogout}>
              <img src={IconUser} alt="icon" />
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </section>
    </div>
  );
};
export default Sidebar;
