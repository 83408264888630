import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import yeslocker from "../../images/new images/longyes.png";
import nolocker from "../../images/new images/longno.png";
import shortyes from "../../images/new images/shortyes.png";
import shortno from "../../images/new images/shortno.png";
import lock from "../../images/Lock.svg";
import Swal from "sweetalert2";
import mockup from "../../images/new images/gray.png";
import "sweetalert2/dist/sweetalert2.min.css";
import VideoTest from "../../assets/video/video-test.mp4";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import Header from "../partials/UserHeader";
import DecVideCall from "../../images/dec-video-call.png";
import MuteSwitch from "../../images/muted_mic_icon.svg";
import UnmuteSwitch from "../../images/unmuted_mic_ico n.svg";
import UnmuteVideo from "../../images/muted_vdo_icon.svg";
import SwtchVideo from "../../images/unmute_vdo_icon.svg";
import backgroundMusicClick from "../../assets/click.mp3";
import Peer from "simple-peer";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const url = process.env.REACT_APP_APP_BACK_URL;
const socketUrl = process.env.REACT_APP_APP_SOCKET_URL;
const socket = io.connect(socketUrl);
const DashboardScr = () => {
  let navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user);
  let { slug } = useParams();
  slug = slug.toLowerCase();
  const [isLoading, setIsLoading] = useState(false);
  const [userStream, setUserStream] = useState();
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [getLocker, setLocker] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabletMode, setTabletMode] = useState(false);
  const [selectedLocker, setselectedLocker] = useState("");
  //video call streaming
  const myButtonRef = useRef(null);
  let peer = null;
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const myVideo = useRef(null);
  const userVideo = useRef(null);
  const connectionRef = useRef(null);
  const leaveCallButtonRef = useRef(null);
  const [stream, setStream] = useState();
  const [audioTrack, setAudioTrack] = useState(null);
  const [videoTrack, setVideoTrack] = useState(null);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [callDeclined, setcallDeclined] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const clickButton = useRef(null);

  useEffect(() => {
    socket.emit("user_login", slug);
    // siteLockers();

    // callUser(slug);
    return () => {
      leaveCall();
    };
  }, [slug]);
  useEffect(() => {
    const handleHardRefresh = () => {
      if (performance.navigation.type === 1) {
        if (videoShow) {
          leaveCall();
        }
      }
    };

    window.addEventListener("unload", handleHardRefresh);

    return () => {
      window.removeEventListener("unload", handleHardRefresh);
    };
  }, []);
  async function setStreamFun() {
    try {
      setVideoShow(true);
      const streamObj = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (myVideo.current) {
        myVideo.current.srcObject = streamObj;
      }
      const audioTrack = streamObj.getAudioTracks()[0];
      audioTrack.enabled = true;
      const videoTrack = streamObj.getVideoTracks()[0];
      videoTrack.enabled = true;
      setStream(streamObj);
      setAudioTrack(audioTrack);
      setVideoTrack(videoTrack);

      socket.on("connectCall", (data) => {
        setIsLoading(true);
        const delayInMilliseconds = 1000; // 10 seconds
        // Update elapsed time every second
        let intervalId = "";
        const timeoutId = setTimeout(() => {
          if (myButtonRef.current) {
            myButtonRef.current.click();
          }
          setIsLoading(false);
        }, delayInMilliseconds);
        // Make sure to clear the timeout if the component unmounts
        return () => {
          clearTimeout(timeoutId);
        };
      });
      socket.on("destroyConnection", (data) => {
        destroyConnection();
        // stopCamera();
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const destroyConnection = async () => {
    stopCamera();
    setCallEnded(true);
    setcallDeclined(true);
    setTabletMode(false);
    setVideoShow(false);
    setIsOpen(false);

    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    //  Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;

    const result = await Swal.fire({
      title: "Call Declined?",
      text: "Call declined by admin!",
      icon: "warning",
    });

    const delayInMilliseconds = 1000; // 10 seconds
    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      if (leaveCallButtonRef.current) {
        leaveCallButtonRef.current.click();
        setVideoShow(false);
      }
    }, delayInMilliseconds);
    // Make sure to clear the timeout if the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
    // navigate('/chats');
  };
  const clickButtonFun = async () => {
    backgroundAudioClick.play();
  };

  const callUser = async (slug) => {
    try {
      setCallEnded(false);
      if (peer && !peer.destroyed) {
        peer.destroy();
      }
      // Create a promise that resolves when the stream is set
      const streamPromise = new Promise((resolve, reject) => {
        peer = new Peer({
          initiator: true,
          trickle: false,
          stream: stream,
        });
        peer.on("signal", (data) => {
          socket.emit("callUser", {
            caller: "VA" + slug,
            name: slug,
            remote: "",
            signalData: data,
          });
        });
        peer.on("stream", (stream) => {
          if (userVideo.current) {
            userVideo.current.srcObject = stream;
          }
          resolve(); // Resolve the promise when the stream is set
        });
        socket.on("callAccepted", (signal) => {
          setCallAccepted(true);
          try {
            // Check if peer is destroyed before signaling
            if (!peer || peer.destroyed) {
              throw new Error("Peer is destroyed");
            }
            peer.signal(signal);
          } catch (error) {
            // Swallow the error without logging it
          }
        });
        socket.on("callEnded", () => {
          console.log(peer, "peering connection");
          // Call ended, clean up
          peer.destroy();
          setStream(null);
          console.log(peer, "distroy peering connection");
        });
        connectionRef.current = peer;
        console.log("connection", connectionRef.current);
      });
      // Wait for the stream to be set before continuing
      await streamPromise;
      // Continue with other functionality that depends on the stream
      // ...
    } catch (error) {
      // Handle any errors that may occur during the process
      console.error("Error in callUser:", error);
    }
  };
  const leaveCall = () => {
    socket.emit("destroyCallConnection", {
      caller: "VA" + slug,
    });
    if (clickButton.current) {
      clickButton.current.click();
    }
    stopCamera();
    setIsOpen(false);
    setIsOpen(false);
    setCallEnded(true);
    setStream(null);
    setVideoShow(false);
    setCallAccepted(false);
    // setcallDeclined(true);
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }

    connectionRef.current = null;
    const delayInMilliseconds = 1000; // 10 seconds
    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      setVideoShow(false);
      setCallEnded(false);
    }, delayInMilliseconds);
  };
  // leave video call
  const userlLeaveCall = async () => {
    stopCamera();
    setCallEnded(false);
    setVideoShow(false);
    setcallDeclined(false);
    setCallAccepted(false);
    setIsOpen(false);
    setStream(null);

    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;
  };
  function stopCamera() {
    if (stream) {
      // Stop all tracks in the stream
      stream.getTracks().forEach((track) => track.stop());
      // Optional: If you want to clear the stream object
      setStream(null);
    }
  }
  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to socket.io server");
    });
    siteLockers();
  }, []);

  const startChat = () => {
    const { width } = getWindowDimensions();
    const surfaceTabletWidth = 768;
    if (width === surfaceTabletWidth) {
      setIsOpen(false);
      setTabletMode(true);
    } else {
      setIsOpen(true);
    }
    socket.emit("sendNotification", slug);
    setStreamFun();
  };
  function closeeModal() {
    setIsOpen(false);
  }
  // get all site
  const siteLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();

      if (json.success) {
        console.log(json, "Lockers Response");
        setselectedLocker("");
        setIsDisplay(true);
        setLocker(json.lockers);
        console.log(json.lockers[0]._id);
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // open locker
  const openLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen(true);
    setIsLoading(true);
    const response = await fetch(`${url}/api/sites/relay/state/update`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        state: state,
      }),
    });

    setIsLockerOpen(false);
    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
    } else {
      toast.error(json.error);
    }
    setIsLoading(false);
    siteLockers();
  };
  //   modal functions
  const openModal = (id) => {
    setIsModalOpen(true);
    setselectedLocker(id);
  };
  // set id
  const displayOpen = async (id) => {
    const result = await Swal.fire({
      title: "Do you want to open this locker?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setselectedLocker(id);
      // if (selectedLocker === id) {
      openLocker(id);
      // }
    }
  };

  const displayOpen1 = async (id) => {
    const result = await Swal.fire({
      title: "Do you want to open this locker?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setselectedLocker(id);
      // if (selectedLocker === id) {
      openModal(id);
      // }
    }
  };

  const closeModal = () => {
    setselectedLocker("");
    setIsModalOpen(false);
  };
  // return to available locker
  const returnLocker = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/sites/changeToAvailable/${selectedLocker}`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );

    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
      siteLockers();
      setIsLoading(false);
      setIsModalOpen(false);
    } else {
      toast.error(json.error);
    }
  };
  const toggleAudioMute = () => {
    if (clickButton.current) {
      clickButton.current.click();
    }
    if (audioTrack) {
      audioTrack.enabled = !isAudioMuted;
      setIsAudioMuted(!isAudioMuted);
    }
  };
  const toggleVideoMute = () => {
    if (clickButton.current) {
      clickButton.current.click();
    }
    if (videoTrack) {
      videoTrack.enabled = !isVideoMuted;
      setIsVideoMuted(!isVideoMuted);
    }
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          <Header onIconClick={startChat} />
          <div className="container-fluid text-center p-3 ">
            <div style={{ position: "relative" }}>
              <div className="form-box card-box ">
                <div className="row">
                  <div className="col-lg-12 locker-side  ">
                    <div className="row gap locker-row mt-3 overflow-auto">
                      {getLocker[0] && (
                        <>
                          <div className="col-1 "></div>
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[0]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers">
                              <span className="dot">{1}</span>
                              {getLocker[0].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[0]._id && (
                                    <div className="locker-button-text">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[0]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[0]._id && (
                                    <div className="locker-button-text">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[0]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {getLocker[3] && (
                        <div
                          className={`col-2  ${
                            isLoading && selectedLocker !== getLocker[3]._id
                              ? "loading-disabled"
                              : ""
                          }`}
                        >
                          <div className="lockers">
                            <span className="dot">{4}</span>

                            {getLocker[3].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[3]._id && (
                                  <div className="locker-button-text">OPEN</div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[3]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[3]._id && (
                                  <div className="locker-button-text">
                                    Close
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() => displayOpen1(getLocker[3]._id)}
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-2 ">
                        <div className="lockers">
                          {getLocker[6] && (
                            <div
                              className={`lockers-short ${
                                isLoading && selectedLocker !== getLocker[6]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <span className="dot1">{7}</span>
                              {getLocker[6].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[6]._id && (
                                    <div className="locker-button-text ">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[6]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[6]._id && (
                                    <div className="locker-button-text">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={shortno}
                                    onClick={() =>
                                      displayOpen1(getLocker[6]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          )}
                          {getLocker[7] && (
                            <div
                              className={`lockers-short half-locker ${
                                isLoading && selectedLocker !== getLocker[7]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <span className="dot2">{getLocker[7].relay}</span>
                              {getLocker[7].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[7]._id && (
                                    <div className="locker-button-text locker-button-text-small">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[7]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[7]._id && (
                                    <div className="locker-button-text locker-button-text-small">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={shortno}
                                    onClick={() =>
                                      displayOpen1(getLocker[7]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {getLocker[10] && (
                        <div
                          className={`col-2  ${
                            isLoading && selectedLocker !== getLocker[10]._id
                              ? "loading-disabled"
                              : ""
                          }`}
                        >
                          <div className="lockers">
                            <span className="dot">{11}</span>
                            {getLocker[10].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[10]._id && (
                                  <div className="locker-button-text">OPEN</div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[10]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[10]._id && (
                                  <div className="locker-button-text">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() =>
                                    displayOpen1(getLocker[10]._id)
                                  }
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {getLocker[13] && (
                        <>
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[13]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers">
                              <span className="dot">{14}</span>
                              {getLocker[13].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[13]._id && (
                                    <div className="locker-button-text">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[13]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[13]._id && (
                                    <div className="locker-button-text">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[13]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-1 "></div>
                        </>
                      )}
                    </div>
                    <div className="row locker-row mt-3">
                      {getLocker[1] && (
                        <>
                          <div className="col-1 "></div>
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[1]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers">
                              <span className="dot">{2}</span>
                              {getLocker[1].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[1]._id && (
                                    <div className="locker-button-text">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[1]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[1]._id && (
                                    <div className="locker-button-text">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[1]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {getLocker[4] && (
                        <div
                          className={`col-2  ${
                            isLoading && selectedLocker !== getLocker[4]._id
                              ? "loading-disabled"
                              : ""
                          }`}
                        >
                          <div className="lockers">
                            <span className="dot">{5}</span>
                            {getLocker[4].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[4]._id && (
                                  <div className="locker-button-text">OPEN</div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[4]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[4]._id && (
                                  <div className="locker-button-text">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() => displayOpen1(getLocker[4]._id)}
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-2 ">
                        <div className="lockers">
                          <img src={mockup} alt="yes" />
                        </div>
                      </div>
                      {getLocker[11] && (
                        <div
                          className={`col-2  ${
                            isLoading && selectedLocker !== getLocker[11]._id
                              ? "loading-disabled"
                              : ""
                          }`}
                        >
                          <div className="lockers">
                            <span className="dot">{12}</span>
                            {getLocker[11].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[11]._id && (
                                  <div className="locker-button-text">OPEN</div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[11]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[11]._id && (
                                  <div className="locker-button-text">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() =>
                                    displayOpen1(getLocker[11]._id)
                                  }
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {getLocker[14] && (
                        <>
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[14]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers">
                              <span className="dot">{15}</span>
                              {getLocker[14].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[14]._id && (
                                    <div className="locker-button-text">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[14]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[14]._id && (
                                    <div className="locker-button-text">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[14]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-1 "></div>
                        </>
                      )}
                    </div>

                    <div className="row locker-row mt-3">
                      {getLocker[2] && (
                        <>
                          <div className="col-1 "></div>
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[2]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers">
                              <span className="dot">{3}</span>
                              {getLocker[2].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[2]._id && (
                                    <div className="locker-button-text">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[2]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[2]._id && (
                                    <div className="locker-button-text">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[2]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {getLocker[5] && (
                        <div
                          className={`col-2  ${
                            isLoading && selectedLocker !== getLocker[5]._id
                              ? "loading-disabled"
                              : ""
                          }`}
                        >
                          <div className="lockers">
                            <span className="dot">{6}</span>
                            {getLocker[5].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[5]._id && (
                                  <div className="locker-button-text">OPEN</div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[5]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[5]._id && (
                                  <div className="locker-button-text">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() => displayOpen1(getLocker[5]._id)}
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-2 ">
                        <div className="lockers">
                          {getLocker[8] && (
                            <div
                              className={`lockers-short ${
                                isLoading && selectedLocker !== getLocker[8]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <span className="dot1">{9}</span>
                              {getLocker[8].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[8]._id && (
                                    <div className="locker-button-text">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[8]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[8]._id && (
                                    <div className="locker-button-text">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={shortno}
                                    onClick={() =>
                                      displayOpen1(getLocker[8]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          )}
                          {getLocker[9] && (
                            <div
                              className={`lockers-short half-locker ${
                                isLoading && selectedLocker !== getLocker[9]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <span className="dot2">{10}</span>
                              {getLocker[9].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[9]._id && (
                                    <div className="locker-button-text locker-button-text-small">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[9]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[9]._id && (
                                    <div className="locker-button-text locker-button-text-small">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={shortno}
                                    onClick={() =>
                                      displayOpen1(getLocker[9]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {getLocker[12] && (
                        <div
                          className={`col-2  ${
                            isLoading && selectedLocker !== getLocker[12]._id
                              ? "loading-disabled"
                              : ""
                          }`}
                        >
                          <div className="lockers">
                            <span className="dot">{13}</span>
                            {getLocker[12].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[12]._id && (
                                  <div className="locker-button-text">OPEN</div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[12]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[12]._id && (
                                  <div className="locker-button-text">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() =>
                                    displayOpen1(getLocker[12]._id)
                                  }
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {getLocker[15] && (
                        <>
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[15]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers">
                              <span className="dot">{16}</span>
                              {getLocker[15].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[15]._id && (
                                    <div className="locker-button-text">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[15]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[15]._id && (
                                    <div className="locker-button-text">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[15]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-1 "></div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className=" d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center"
                style={{
                  maxWidth: "500px",
                  margin: "auto",
                }}
              >
                {tabletMode && stream && videoShow && (
                  <div
                    className="p-1 w-sm-50 h-sm-50 col-12 rounded-3"
                    style={{
                      background: "linear-gradient(#323232, #4c4c4c)",
                      position: "relative",
                    }}
                  >
                    <div className=" ">
                      {!callAccepted && (
                        <div
                          className="lds-spinner"
                          style={{ display: "flex", top: "120px" }}
                        >
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      <div>
                        <ReactPlayer
                          playing
                          muted
                          style={{
                            position: "absolute",
                            zIndex: "99999999",
                            top: "3%",
                            right: "2%",
                            objectFit: "cover",
                          }}
                          height="20%"
                          width="20%"
                          url={stream}
                        />
                        {!callEnded ? (
                          <video
                            width="100%"
                            ref={userVideo}
                            autoPlay
                            playsInline
                          />
                        ) : (
                          <video autoPlay playsInline />
                        )}
                        {/* {!callAccepted && <h5>Call Connecting ...</h5>} */}
                        {callDeclined && <h5>Call Declined by Admin...</h5>}
                      </div>
                    </div>
                    {videoShow && !callEnded && (
                      <>
                        <div className="icons-wrap-end-call my-3">
                          <div
                            className="icon-call caller-icon d-flex justify-content-center align-items-center"
                            style={{
                              background: "rgb(2, 1, 93)",
                              width: "35px",
                              height: "35px",
                            }}
                            onClick={toggleVideoMute}
                          >
                            {isVideoMuted ? (
                              <img
                                src={SwtchVideo}
                                alt="Unmute Video"
                                className="mx-2 d-flex justify-content-center"
                                width={27}
                                height={27}
                              />
                            ) : (
                              <img
                                src={UnmuteVideo}
                                alt="Mute Video"
                                className="mx-2 d-flex justify-content-center  rounded"
                                width={27}
                                height={27}
                              />
                            )}
                          </div>
                          <Link onClick={leaveCall}>
                            <div className="icon-call dec-call-video">
                              <img src={DecVideCall} alt="Decline Call" />
                            </div>
                          </Link>
                          <div
                            className="icon-call d-flex  align-items-center"
                            style={{
                              background: "rgb(2, 1, 93)",
                              width: "35px",
                              height: "35px",
                            }}
                            onClick={toggleAudioMute}
                          >
                            {isAudioMuted ? (
                              <img
                                src={UnmuteSwitch}
                                alt="UnMute Call"
                                className="mx-2 d-flex justify-content-center"
                                width={27}
                                height={27}
                              />
                            ) : (
                              <img
                                src={MuteSwitch}
                                alt="Mute Call"
                                className="mx-2 d-flex justify-content-center  rounded "
                                width={27}
                                height={27}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <a ref={myButtonRef} onClick={() => callUser(slug)}></a>
          <a ref={leaveCallButtonRef} onClick={userlLeaveCall}></a>
          <a ref={clickButton} onClick={clickButtonFun}></a>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            className="modal-content"
            style={{
              overlay: {
                backgroundColor: "rgb(43 41 41 / 75%)", // Update the background color here
              },
              content: {
                backgroundColor: "white",
                padding: "50px",
              },
            }}
            shouldCloseOnOverlayClick={false}
          >
            <img src={lock} alt="lock" className="modal-image" />
            <p className="modal-text mt-3">
              Do you want to release this locker?
            </p>
            <div className="modal-button">
              <button className="modal-btn1" onClick={returnLocker}>
                Yes
              </button>
              <button className="modal-btn2" onClick={closeModal}>
                No
              </button>
            </div>
          </Modal>
          <div className="modal-caller">
            <Modal
              isOpen={modalIsOpen}
              onClick={closeeModal}
              className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
              style={{
                content: {
                  position: "relative",
                  fontFamily: "Arial",
                  fontWeight: "800",
                  fontSize: "30px",
                  maxWidth: "500px", // Set a maximum width for the modal container
                  maxHeight: "450px",
                  margin: "auto",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
                },
              }}
            >
              <div
                className="p-1 w-sm-50 h-sm-50 col-sm-12 rounded-3"
                style={{
                  background: "linear-gradient(#323232, #4c4c4c)",
                  position: "relative",
                  marginTop: "50px",
                  // marginTop: "120px"
                }}
              >
                {!callAccepted && (
                  <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
                <div className=" ">
                  {stream && videoShow && (
                    <>
                      <ReactPlayer
                        playing
                        muted
                        style={{
                          position: "absolute",
                          zIndex: "99999999",
                          top: "1%",
                          right: "3%",
                          borderRadius: "10px",
                          objectFit: "cover",
                          width: "100%", // Set the width of the video to 100%
                          height: "auto",
                        }}
                        height="25%"
                        width="22%"
                        url={stream}
                      />
                      {!callEnded ? (
                        <video
                          height="100%"
                          width="100%"
                          ref={userVideo}
                          autoPlay
                          playsInline
                          style={{
                            overflow: "auto",
                          }}
                        />
                      ) : (
                        <video autoPlay playsInline />
                      )}

                      {callDeclined && (
                        <h5 className="text-center">
                          Call Declined by Admin...
                        </h5>
                      )}
                    </>
                  )}
                </div>
                {videoShow && !callEnded && (
                  <>
                    <div className="icons-wrap-end-call my-3">
                      <div
                        className="icon-call caller-icon d-flex justify-content-center align-items-center"
                        style={{
                          background: "rgb(2, 1, 93)",
                          width: "35px",
                          height: "35px",
                        }}
                        onClick={toggleVideoMute}
                      >
                        {isVideoMuted ? (
                          <img
                            src={SwtchVideo}
                            alt="Unmute Video"
                            className="mx-2 d-flex justify-content-center"
                            width={27}
                            height={27}
                          />
                        ) : (
                          <img
                            src={UnmuteVideo}
                            alt="Mute Video"
                            className="mx-2 d-flex justify-content-center  rounded"
                            width={27}
                            height={27}
                          />
                        )}
                      </div>
                      <Link onClick={leaveCall}>
                        <div className="icon-call dec-call-video">
                          <img src={DecVideCall} alt="Decline Call" />
                        </div>
                      </Link>
                      <div
                        className="icon-call d-flex  align-items-center"
                        style={{
                          background: "rgb(2, 1, 93)",
                          width: "35px",
                          height: "35px",
                        }}
                        onClick={toggleAudioMute}
                      >
                        {isAudioMuted ? (
                          <img
                            src={UnmuteSwitch}
                            alt="UnMute Call"
                            className="mx-2 d-flex justify-content-center"
                            width={27}
                            height={27}
                          />
                        ) : (
                          <img
                            src={MuteSwitch}
                            alt="Mute Call"
                            className="mx-2 d-flex justify-content-center  rounded "
                            width={27}
                            height={27}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardScr;
