import React, { useEffect, useState } from "react";
import Header2 from "../../Components/partials/Header2";
import Sidebar from "../../Components/partials/Sidebar";
import BackIcon from "../../images/icon-back.png";
// import UploadIcon from '../../../images/icon-upload.png'
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Add = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [appendedHTML, setAppendedHTML] = useState("");

  useEffect(() => {}, []);
  const [credentials, setCredentials] = useState({
    name: "",
    address: "",
    password: "",
    ip_address: "",
    locker_qty: "",
    slug: "",
    port: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleInputSlug = (e) => {
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;

    // Check if the entered value matches the pattern
    if (alphanumericRegex.test(e.target.value) || e.target.value === "") {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    // const response = await fetch("http://54.174.4.89:5000/api/auth/login", {
    //   mode: "cors",
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify({
    //     email: credentials.email,
    //     password: credentials.password,
    //   }),
    // });
    setIsLoading(true);
    const response = await fetch(`${url}/api/sites/add`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: credentials.name,
        address: credentials.address,
        password: credentials.password,
        ip_address: credentials.ip_address,
        port: credentials.port,
        slug: credentials.slug,
        locker_qty: credentials.locker_qty,
      }),
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        name: "",
        address: "",
        slug: "",
        port: "",
        password: "",
        ip_address: "",
        locker_qty: "",
      });
      toast.success("Site Created Successfully");
    } else {
      setIsLoading(false);
      toast.error(json.error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header2 isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard mt-5">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/sites">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Create Site</h3>
                              <p>
                                List of all the sites registered in the system
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Name</h4>
                                  <p>Enter the name of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="Site Name"
                                    name="name"
                                    value={credentials.name}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Slug</h4>
                                  <p>Enter the unique slug of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="Site Slug"
                                    name="slug"
                                    value={credentials.slug}
                                    onChange={handleInputSlug}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Password</h4>
                                  <p>Enter the password of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="password"
                                    placeholder="site password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site IP</h4>
                                  <p>Enter the IP address of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    placeholder="192.168.10.1"
                                    name="ip_address"
                                    required
                                    value={credentials.ip_address}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Port</h4>
                                  <p>Enter the PORT address of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    placeholder="5000"
                                    name="port"
                                    required
                                    value={credentials.port}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Lockers Quantity</h4>
                                  <p>Enter the Locker quantity of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="number"
                                    placeholder="10"
                                    name="locker_qty"
                                    required
                                    value={credentials.locker_qty}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Address</h4>
                                  <p>Enter complete address of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <textarea
                                    name="address"
                                    id=""
                                    cols="30"
                                    rows="3"
                                    value={credentials.address}
                                    onChange={handleInput}
                                  >
                                    2405 Wesbrook Mall, Vancouver, BC V6T 1Z4,
                                    Canada
                                  </textarea>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <a
                                href=""
                                className="btn btn-prim btn-transparent w-auto"
                              >
                                Reset Form
                              </a>
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                disabled={isLoading}
                              >
                                {isLoading ? "Creating..." : "create site"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Add;
