import React, { useEffect, useState } from "react";
import "../../simplelocker.css";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import { toast } from "react-toastify";
import {
  checkFileExists,
  formattedDateTime1,
  formattedDateTime,
} from "../../utils/generic";
import { io } from "socket.io-client";

const imageUrl = process.env.REACT_APP_APP_IMAGE_URL;
const url = process.env.REACT_APP_APP_BACK_URL;
const socket_url = process.env.REACT_APP_APP_SOCKET_URL;
const socket = io.connect(socket_url);
let idleTimer;

const Header = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const isAuthenticated = useSelector((state) => state.user);
  let { slug } = useParams();
  const [stream, setStream] = useState();
  const [site, setSite] = useState({});
  const [siteLogo, setSiteLogo] = useState(url + "/locker_logo.png");
  const [temparature, settemparature] = useState("66.0");
  const idleTimeoutInSeconds = isAuthenticated ? isAuthenticated.idle_time : 0; // 5 minutes (adjust as needed)

  // async function setStreamFun() {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       audio: false,
  //       video: false,
  //     });

  //     const audioTrack = stream.getAudioTracks()[0];

  //     // Mute the audio track by default
  //     audioTrack.enabled = true;
  //     const videoTrack = stream.getVideoTracks()[0];
  //     setStream(stream);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }
  useEffect(() => {
    siteData();
    // setStreamFun();
    if (isAuthenticated && isAuthenticated.role !== "super admin") {
      resetIdleTimer();
      const activityEvents = ["mousemove", "keydown", "mousedown", "scroll"];
      const onActivity = () => {
        resetIdleTimer();
      };
      activityEvents.forEach((event) => {
        window.addEventListener(event, onActivity);
      });
      return () => {
        activityEvents.forEach((event) => {
          window.removeEventListener(event, onActivity);
        });
      };
    }
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);

    return () => {
      // stopCamera();
      clearInterval(intervalId);
    };
  }, [temparature]);
  // function stopCamera() {
  //   if (stream) {
  //     stream.getTracks().forEach((track) => track.stop());
  //     setStream(null);
  //   }
  // }
  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(handle_logout, idleTimeoutInSeconds * 1000);
  };
  const handle_logout = async (e) => {
    if (site.required_pin) {
      localStorage.removeItem("token");
      navigate(`/${slug}/pin`);
      dispatch(logout());
    }
  };
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/get-temperature`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      const json = await response.json();
      if (json.success) {
        settemparature(json.data);
      }
    } catch (error) { }
  };

  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSite(json.site);
        const isExist = await checkFileExists(json?.site?.image);
        setSiteLogo(imageUrl + json.site.image);
      } else {
        toast.error("No site found! Enter valid site");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <header className="header">
        <div className="row mb-2">
          <div className="prof-sett">
            <div className="col-4 d-flex justify-content-center ">
              <div className="main-heading">
                <div className="locker-logo">
                  <div className="locker-style-image d-flex justify-content-center ">
                    <img
                      className="logo-image-style img-fluid mx-auto"
                      src={siteLogo}
                      alt="Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-center ">
              <h3 className="fw-700 bg-white p-2 rounded shadow-sm">{temparature}°F</h3>
            </div>

            <div className="col-4 d-flex justify-content-center px-3">
              <div className="locker-timer ">
                <p>{formattedDateTime(currentDateTime)}</p>
                <p>{formattedDateTime1(currentDateTime)}</p>


                <Link
                  to={`/${slug}/admin/pin`}
                  className="bg-white px-3 "
                  style={{
                    border: "1px solid #012267",
                    borderRadius: "4px",
                    textDecoration: "none",
                    color: "black",
                  }}

                >
                  Admin
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
