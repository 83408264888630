import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
const url = process.env.REACT_APP_APP_SOCKET_URL;
const socket = io.connect(url);

const VideoChat = () => {
  const myButtonRef = useRef(null);
  const remoteButtonRef = useRef(null);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const remote = queryParams.get("remote");
  const { roomId, id } = useParams();
  const isAuthenticated = "";
  const [me, setMe] = useState("");
  const [stream, setStream] = useState(null);
  const [userstream, setUserStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [idToCall, setIdToCall] = useState("");
  const [callEnded, setCallEnded] = useState(false);
  const [callDeclined, setcallDeclined] = useState(false);
  const [name, setName] = useState("");
  const myVideo = useRef(null);
  const userVideo = useRef(null);
  const connectionRef = useRef(null);
  const [videoCallUser, setVideoCallUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const senderId = "";
  const [messageInput, setMessageInput] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const countRef = useRef(newMessage);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [audioTrack, setAudioTrack] = useState(null);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [videoTrack, setVideoTrack] = useState(null);
  const [callAccepted, setCallAccepted] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  let navigate = useNavigate();
  let { slug } = useParams();
  useEffect(() => {
    async function setStreamFun() {
      try {
        const strm = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        const audioTrack = strm.getAudioTracks()[0];
        // Mute the audio track by default
        audioTrack.enabled = true;
        const videoTrack = strm.getVideoTracks()[0];
        // Mute the video track by default
        videoTrack.enabled = true;
        setStream(strm);
        setAudioTrack(audioTrack);
        setVideoTrack(videoTrack);
        if (myVideo.current) {
          myVideo.current.srcObject = strm;
        }
        socket.emit("callUser2", {
          caller: senderId + "1a",
        });
        socket.on("connectCall", (data) => {
          const delayInMilliseconds = 5000; // 10 seconds
          // Update elapsed time every second
          let intervalId = "";
          const timeoutId = setTimeout(() => {
            if (myButtonRef.current) {
              myButtonRef.current.click();
            }
          }, delayInMilliseconds);
          // Make sure to clear the timeout if the component unmounts
          return () => {
            clearTimeout(timeoutId);
          };
        });
        socket.on("callUser1", (data) => {
          setReceivingCall(true);
          setCaller(data.from);
          setName(data.name);
          setCallerSignal(data.signal);
          const delayInMilliseconds = 5000; // 10 seconds
          const timeoutId = setTimeout(() => {
            if (remoteButtonRef.current) {
              remoteButtonRef.current.click();
            }
          }, delayInMilliseconds);
          // Make sure to clear the timeout if the component unmounts
          return () => clearTimeout(timeoutId);
        });
        socket.on("destroyConnection", (data) => {
          console.log("destroy connection");
          destroyConnection();
        });
        // window.addEventListener('beforeunload', (event) => {
        //   // Save call-related information to persistent storage
        //   localStorage.setItem('callInfo', JSON.stringify({
        //     stream,
        //     myVideo,
        //     userVideo,
        //     connectionRef,
        //     // Add other relevant data
        //   }));
        // });
        let newConnection;
        // if (connection) {
        //   // Re-establish connection if it exists
        //   newConnection = connection;
        // } else {
        //   // Initialize a new connection
        //   newConnection = new Peer({
        //     initiator: true,
        //     trickle: false,
        //     stream: stream,
        //   });
        //   dispatch(setConnection(newConnection)); // Store the connection in Redux
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setStreamFun();

    socket.on("pageRefesh", (data) => {
      localStorage.removeItem("streamDone");
      window.location.reload();
    });
    if (localStorage.getItem("streamDone")) {
      socket.emit("pageHardRefesh", {
        caller: id + "1a",
      });
      // Reload the page
    }
    // Function to add the event listener

    return () => {
      localStorage.removeItem("streamDone");

      if (stream) {
        // Release camera permissions when the component unmounts
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [senderId, id]);
  useEffect(() => {
    socket.emit("recieverOnline", {
      caller: slug,
    });
  }, []);
  useEffect(() => {
    // Start the timer when the call is accepted
    if (callAccepted && !callEnded) {
      const intervalId = setInterval(updateElapsedTime, 1000); // Update every second

      // Make sure to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [callAccepted, callEnded]);
  const callUser = async (roomId) => {
    try {
      // Create a promise that resolves when the stream is set
      const streamPromise = new Promise((resolve, reject) => {
        const peer = new Peer({
          initiator: true,
          trickle: false,
          stream: stream,
        });
        peer.on("signal", (data) => {
          socket.emit("callUser", {
            caller: roomId + "1a",
            remote: '',
            signalData: data,
            name: "imran",
          });
        });
        peer.on("stream", (stream) => {
          setUserStream(stream);
          if (userVideo.current) {
            userVideo.current.srcObject = stream;
          }
          resolve(); // Resolve the promise when the stream is set
        });
        socket.on("callAccepted", (signal) => {
          setCallAccepted(true);
          peer.signal(signal);
        });
        connectionRef.current = peer;
        localStorage.setItem("streamDone", true);
      });
      // Wait for the stream to be set before continuing
      await streamPromise;
      // Continue with other functionality that depends on the stream
      // ...
    } catch (error) {
      // Handle any errors that may occur during the process
      console.error("Error in callUser:", error);
    }
  };
  const answerCall = (roomId) => {
    setCallAccepted(true);
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });
    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: roomId + "1a" });
    });
    peer.on("stream", (strme) => {
      setUserStream(strme);
      if (userVideo.current) {
        // Set the srcObject of the video element
        userVideo.current.srcObject = strme;
      }
    });
    peer.signal(callerSignal);
    connectionRef.current = peer;
    localStorage.setItem("streamDone", true);
  };
  const handleSendNotification = (userId, message) => {
    // Send the notification to the server via WebSocket
    socket.emit("sendNotification", { userId, message });
  };
  const leaveCall = () => {
    socket.emit("destroyCallConnection", {
      caller: id + "1a",
    });
    setCallEnded(true);
    connectionRef.current.destroy();
    navigate("/chats");
  };
  const destroyConnection = () => {
    setCallEnded(true);
    setcallDeclined(true);
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    connectionRef.current.destroy();
    const delayInMilliseconds = 5000; // 10 seconds
    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      navigate("/chats");
    }, delayInMilliseconds);
    // Make sure to clear the timeout if the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
    // navigate('/chats');
  };
  const toggleAudioMute = () => {
    if (audioTrack) {
      audioTrack.enabled = !isAudioMuted;
      setIsAudioMuted(!isAudioMuted);
    }
  };
  const toggleVideoMute = () => {
    if (videoTrack) {
      videoTrack.enabled = !isVideoMuted;
      setIsVideoMuted(!isVideoMuted);
    }
  };
  function updateElapsedTime() {
    setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
  }
  return (
    <div>
      <h1>Video Call</h1>
      <div className="w-25">
        {!callEnded ? (
          <video ref={userVideo} autoPlay playsInline />
        ) : (
          <video autoPlay playsInline />
        )}
      </div>
    </div>
  );
};

export default VideoChat;
