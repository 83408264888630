import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";

const url = process.env.REACT_APP_STREAMIN_SOCKET_URL;
const LiveStreaming2 = () => {
  const { id } = useParams();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/rtsp-relay@1.7.0/browser/index.js";
    script.async = true;
    script.onload = () => {
      /* eslint-disable no-undef */
      loadPlayer({
        url: `ws://localhost:5001/api/stream/${id}`, // Replace with your desired channel ID
        canvas: document.getElementById('canvas')
      });
      /* eslint-enable no-undef */
    };
    document.head.appendChild(script);
    return () => {
      // Clean up the script element if the component unmounts
      document.head.removeChild(script);
    };
  }, [IDBIndex]);
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                {/* <Link to="/dashboard">
                <div className="back-icon">
                  <img src={BackIcon} alt="" />
                </div>
              </Link> */}
                <div>
                  <h3>Live Streaming</h3>
                  <p>View Live Streaming </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        <div className="form-box card-box">
          <canvas id="canvas" height={'500px'} width={'9000px'}></canvas>
        </div>
      </div>
    </div>
  )
}
export default LiveStreaming2