import React, { useEffect, useState } from "react";
import Header from "../../Components/partials/Header2";
import Sidebar from "../../Components/partials/Sidebar";
import BackIcon from '../../images/icon-back.png'
import UploadIcon from '../../images/icon-upload.png'
import { useSelector } from 'react-redux';
import styles from './style.module.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const Edit = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const [appendedHTML, setAppendedHTML] = useState('');
  useEffect(() => {
    getLocker(id);
  }, [id]);
  const [credentials, setCredentials] = useState({ number: "", relay: "", status: "" ,port:""});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    // const response = await fetch("http://54.174.4.89:5000/api/auth/login", {
    //   mode: "cors",
    //   method: "POST",
    //   headers: headers,
    //   body: JSON.stringify({
    //     email: credentials.email,
    //     password: credentials.password,
    //   }),
    // });
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/sites/updateLocker/${id}`,
      {
        mode: "cors",
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          number: credentials.number,
          relay: credentials.relay,
          status: credentials.status,
          port: credentials.port,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      toast.success('Locker Updated Successfully');

    } else {
      setIsLoading(false);
      toast.error(json.error);
    }
  };
  // get site data
  // get role
  const getLocker = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getLocker/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setCredentials({ ...json.locker });
      }

    } catch (error) {
      console.error(error);
    }
  };

  const [isActive, setIsActive] = useState(false);

  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>

                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard mt-5">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">

                            <div className="user-back-det">
                              <h3>Update Locker</h3>
                              <p>
                                List of all the locker registered in the site
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>locker number</h4>
                                  <p>Enter the number of the locker</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="number"
                                    placeholder="Site Name"
                                    name="number"
                                    value={credentials.number}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>status</h4>
                                  <p>Select the status of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <select name="status" required value={credentials.status} onChange={handleInput}>
                                    <option value="">Choose Status</option>
                                    <option value="available">Available</option>
                                    <option value="occupied">Occupied</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>relay</h4>
                                  <p>Enter the relay of the locker</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    placeholder="1"
                                    name="relay"
                                    required
                                    value={credentials.relay}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Port</h4>
                                  <p>Enter the port of the locker</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    placeholder="1"
                                    name="port"
                                    required
                                    value={credentials.port}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>


                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                             
                              <button type="submit" className="btn btn-prim w-auto ms-5" disabled={isLoading}>
                                {isLoading ? 'Updating...' : 'update locker'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Edit;
